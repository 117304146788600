import React, { ComponentProps } from 'react'

export interface LoginErrorProps extends ComponentProps<'div'> {
  error: Error
}

export const LoginError = ({
  className = '',
  error,
  title = 'Unable to authenticate this app right now',
  ...props
}: LoginErrorProps) => {
  return (
    <div className={`${className} flex-center flex-col`} {...props}>
      <h3 className="text-2xl text-gray-800">{title}</h3>
      <p className="text-gray-500">{error.message}</p>
    </div>
  )
}
