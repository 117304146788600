import { useUserData } from '@nhost/react'
import React from 'react'
import { useLogin } from '../hooks'
import { LoadingScreen } from './LoadingScreen'
import { LoginError } from './LoginError'
import { WidgetList } from './WidgetList'

export const App = () => {
  const user = useUserData()

  const { error, loading, outOfContext } = useLogin()

  if (error) {
    return (
      <main className="h-screen flex-center">
        <LoginError
          error={error}
          title={outOfContext ? 'You may close this tab now.' : undefined}
        />
      </main>
    )
  }

  if (loading) {
    return <LoadingScreen className="h-screen flex-center" />
  }

  if (outOfContext || !user) {
    return (
      <main className="h-screen flex-center">You may close this tab now.</main>
    )
  }

  return <WidgetList />
}
