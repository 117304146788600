import {
  ArrowPathIcon,
  DocumentIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/24/outline'
import { Button, TextInput, TextArea, toast } from '@8thday/react'
import { useNhostClient } from '@nhost/react'
import React, { ComponentProps, FormEvent, useState } from 'react'

export interface ContactFormProps extends ComponentProps<'form'> {
  prefilledSubject?: string
  prefilledBody?: string
  prefilledEmail?: string
  formTitle?: string
}

export const ContactForm = ({
  className = '',
  prefilledBody = '',
  prefilledEmail = '',
  prefilledSubject = '',
  formTitle = 'Contact Us',
  onSubmit: onComplete,
  ...props
}: ContactFormProps) => {
  const nhost = useNhostClient()
  const [email, setEmail] = useState(prefilledEmail)
  const [topic, setTopic] = useState(prefilledSubject)
  const [request, setRequest] = useState(prefilledBody)
  const [sending, setSending] = useState(false)
  const valid = email && topic && request

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (!valid || sending) return

    setSending(true)

    const res = await nhost.functions
      .call('contact', { email, topic, request })
      .catch((err) =>
        err instanceof Error ? err : new Error(JSON.stringify(err))
      )

    if (res instanceof Error || res.error) {
      setSending(false)
      toast.error({
        description: 'There was a problem sending your message :(',
      })
      return console.error(res)
    }

    setSending(false)
    setEmail('')
    setTopic('')
    setRequest('')
    onComplete?.(e)
    toast.success({ description: 'The package has been delivered.' })
  }

  return (
    <form
      className={`${className} flex flex-col space-y-2 w-full min-w-sm`}
      {...props}
      onSubmit={onSubmit}
    >
      <h3 className="text-xl text-center">{formTitle}</h3>
      <TextInput
        label="Your Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            e.stopPropagation()
          }
        }}
        required
      />
      <TextInput
        label="Topic"
        value={topic}
        onChange={(e) => setTopic(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            e.stopPropagation()
          }
        }}
        required
      />
      <TextArea
        label="Your Request"
        value={request}
        onChange={(e) => setRequest(e.target.value)}
        required
      />
      <Button
        className="self-center"
        type="submit"
        disabled={!valid}
        PreIcon={
          sending ? ArrowPathIcon : valid ? PaperAirplaneIcon : DocumentIcon
        }
        spin={sending}
      >
        {sending ? 'Sending!' : valid ? 'Send it!' : 'Type it!'}
      </Button>
    </form>
  )
}
