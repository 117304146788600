import { InformationCircleIcon } from '@heroicons/react/24/outline'
import React, { ComponentProps } from 'react'

export interface InfoButtonProps extends ComponentProps<'button'> {}

export const InfoButton = ({ className = '', ...props }: InfoButtonProps) => {
  return (
    <button
      className={`${className} inline text-primary-500 hover:text-primary-600 focus:text-primary-600`}
      {...props}
    >
      <InformationCircleIcon />
    </button>
  )
}
