import { NhostClient, NhostProvider } from '@nhost/react'
import { NhostApolloProvider } from '@nhost/react-apollo'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './components/AppShell'
import { Toaster } from 'react-hot-toast'

class TokenStorage {
  items: Map<string, any>

  constructor() {
    this.items = new Map()
    this.getItem = this.getItem.bind(this)
    this.setItem = this.setItem.bind(this)
    this.removeItem = this.removeItem.bind(this)
    console.log(this)
  }

  public getItem(key: string) {
    console.log('key', this)
    return this.items.get(key)
  }
  public setItem(key: string, value: any) {
    return this.items.set(key, value)
  }
  public removeItem(key: string) {
    return this.items.delete(key)
  }
}

const isLocal = process.env.IS_LOCAL === 'true'

const nhost = new NhostClient(
  isLocal
    ? {
        backendUrl: 'https://primesights.loca.lt',
        clientStorageType: 'custom',
        clientStorage: new TokenStorage(),
      }
    : {
        subdomain: process.env.REACT_APP_NHOST_SUBDOMAIN,
        region: process.env.REACT_APP_NHOST_REGION,
        clientStorageType: 'custom',
        clientStorage: new TokenStorage(),
      }
)

const root = createRoot(document.getElementById('app')!)

root.render(
  <NhostProvider nhost={nhost}>
    <NhostApolloProvider nhost={nhost}>
      <Toaster position="top-right" />
      <App />
    </NhostApolloProvider>
  </NhostProvider>
)
