import React from 'react'
import { SquareCheckoutButton } from './SquareCheckoutButton'

export interface WidgetSwitchProps {
  widgetInstall: any
  className?: string
}

export const WidgetSwitch = ({
  widgetInstall,
  className = '',
}: WidgetSwitchProps) => {
  console.log(widgetInstall)
  switch (widgetInstall.web_widget?.slug) {
    case 'square-subscription-checkout-button':
      return (
        <SquareCheckoutButton
          className={className}
          widgetInstall={widgetInstall}
        />
      )
    default:
      console.error('no component found for widget slug??')
      return null
  }
}
