import { gql } from '@apollo/client'

export const GET_WIDGET_LIST = gql`
  query getWidgetList {
    widget_install {
      id
      data
      created_at
      site_identifier
      web_widget {
        id: slug
        slug
        name
        description
      }
      integrations {
        enabled
        slug
        created_at
        updated_at
      }
    }
  }
`
