import React from 'react'
import { widgetThumbnails } from '../images/widget-thumbnails'

export interface WidgetInfoSwitchProps {
  widgetInstall: any
  className?: string
}

export const WidgetInfoSwitch = ({ widgetInstall }: WidgetInfoSwitchProps) => {
  switch (widgetInstall.web_widget?.slug) {
    case 'square-subscription-checkout-button':
      return (
        <div>
          <h3 className="text-xl text-center mb-4 text-primary-700">
            Square Subscription Checkout Button
          </h3>
          <p className="text-gray-500 mb-4 max-w-2xl">
            This widget adds a "Subscribe" button on individual product pages
            for products in your 3rd party store. By authorizing your Square
            account and then assigning your product SKUs to their corresponding
            Square subscription plans, the Subscription Links widget can now
            show the "Subscribe" button on each product's page.
          </p>
          <img
            className="max-h-64 mx-auto shadow-md"
            src={widgetThumbnails['square-subscription-checkout-button']}
            alt="Widget Preview"
          />
        </div>
      )
    default:
      console.error('no component found for widget slug??')
      return null
  }
}
