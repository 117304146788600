import { Button, Card } from '@8thday/react'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { useAuthQuery } from '@nhost/react-apollo'
import { useApolloClient } from '@apollo/client'
import React, { ComponentProps, useState } from 'react'
import { GET_WIDGET_LIST } from '../graphql'
import { widgetThumbnails } from '../images/widget-thumbnails'
import { ContactForm } from './ContactForm'
import { InfoButton } from './InfoButton'
import { Modal } from './Modal'
import { WidgetInfoSwitch } from './WidgetInfoSwitch'
import { WidgetSwitch } from './WidgetSwitch'

export interface WidgetListProps extends ComponentProps<'main'> {}

export const WidgetList = ({ className = '', ...props }: WidgetListProps) => {
  const apollo = useApolloClient()
  const [showContact, setShowContact] = useState(false)
  const [showInfo, setShowInfo] = useState(false)

  const { data } = useAuthQuery(GET_WIDGET_LIST)

  const widgets = data?.widget_install ?? []

  const [selectedWidgetId, setSelectedWidgetId] = useState('')

  const selectedWidget = widgets.find((w) => w.id === selectedWidgetId)

  return (
    <main className={`${className}`} {...props}>
      {selectedWidget ? (
        <div className="relative flex flex-col items-stretch h-screen">
          <button
            className="absolute left-4 top-4 flex items-center text-sm text-gray-500"
            onClick={() => {
              apollo.reFetchObservableQueries()
              setSelectedWidgetId('')
            }}
          >
            <ArrowLeftIcon className="h-5 w-5" /> All Widgets
          </button>
          <h2 className="text-center my-8 text-2xl text-primary-700">
            {selectedWidget.web_widget?.name}
            <InfoButton
              className="h-6 w-6 ml-2"
              onClick={() => setShowInfo(true)}
            />
          </h2>
          <hr className="mx-4 mb-4 border-gray-300" />
          <WidgetSwitch widgetInstall={selectedWidget} />
          <Modal show={showInfo} onClose={() => setShowInfo(false)}>
            <WidgetInfoSwitch widgetInstall={selectedWidget} />
          </Modal>
        </div>
      ) : (
        <>
          <div className="flex-center flex-col">
            <h2 className="my-8 text-2xl text-primary-700">
              Manage your custom widgets' data and settings
            </h2>
          </div>
          <hr className="mx-4" />
          <ul className="p-4 flex flex-wrap gap-4">
            {widgets.map((wi) => (
              <Card
                as="li"
                key={wi.id}
                className="cursor-pointer max-w-sm"
                onClick={() => setSelectedWidgetId(wi.id)}
              >
                <h3 className="font-bold text-gray-700 text-lg text-center mb-2">
                  {wi.web_widget.name}
                </h3>
                {widgetThumbnails[wi.web_widget?.slug] && (
                  <img
                    className="max-h-36 mx-auto mb-2 shadow-md"
                    src={widgetThumbnails[wi.web_widget?.slug]}
                    alt="Widget Preview"
                  />
                )}
                <p className="text-gray-500 text-sm">
                  {wi.web_widget.description}
                </p>
              </Card>
            ))}
            <Card as="li" className="flex-center flex-col">
              <p className="mb-2 italic text-gray-600">
                Have a custom need for your web presence?
              </p>
              <Button variant="primary" onClick={() => setShowContact(true)}>
                Request a New Widget
              </Button>
            </Card>
          </ul>
          <Modal show={showContact} onClose={() => setShowContact(false)}>
            <ContactForm
              prefilledSubject="New widget request"
              onSubmit={() => setShowContact(false)}
            />
          </Modal>
        </>
      )}
    </main>
  )
}
