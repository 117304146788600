import React, { ComponentProps, useEffect, useMemo, useState } from 'react'
import { useNhostFunction } from '@8thday/nhost-js-utils'
import { SquareLinkingButton } from './SquareLinkingButton'
import { TextInput, Button } from '@8thday/react'
import fastDeepEqual from 'fast-deep-equal'
import clsx from 'clsx'
import { useNhostClient } from '@nhost/react'
import { useApolloClient } from '@apollo/client'
import { UPDATE_PRODUCT_MAPPING } from '../graphql'
import { toast } from '@8thday/react'
import {
  ArrowPathIcon,
  ArrowPathRoundedSquareIcon,
  CheckCircleIcon,
  CloudArrowUpIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import { InfoButton } from './InfoButton'
import { Modal } from './Modal'
// @ts-ignore
import findTheSku from '../images/find-the-sku.png'
// @ts-ignore
import checkTheCheckoutLink from '../images/check-the-checkout-link.png'

interface Money {
  amount: string
  currency: string
}
interface SubscriptionPlanPhase {
  uid: string
  cadence:
    | 'DAILY'
    | 'WEEKLY'
    | 'EVERY_TWO_WEEKS'
    | 'THIRTY_DAYS'
    | 'MONTHLY'
    | 'SIXTY_DAYS'
    | 'EVERY_TWO_MONTHS'
    | 'NINETY_DAYS'
    | 'QUARTERLY'
    | 'EVERY_FOUR_MONTHS'
    | 'EVERY_SIX_MONTHS'
    | 'ANNUAL'
    | 'EVERY_TWO_YEARS'
  periods: number
  recurringPriceMoney: Money
  ordinal: string
}

interface SubscriptionPlan {
  type: 'SUBSCRIPTION_PLAN'
  id: string
  updatedAt: string
  version: string
  isDeleted: boolean
  presentAtAllLocations: boolean
  subscriptionPlanData: {
    name: string
    phases: SubscriptionPlanPhase[]
  }
}

export interface SquareCheckoutButtonProps extends ComponentProps<'div'> {
  widgetInstall: any
}

const emptyProductMapping = {}

export const SquareCheckoutButton = ({
  className = '',
  widgetInstall,
  ...props
}: SquareCheckoutButtonProps) => {
  const siteId = widgetInstall.site_identifier
  const widgetSlug = widgetInstall.web_widget.slug
  const nhost = useNhostClient()
  const apollo = useApolloClient()

  const [showProductSKUInstructions, setShowProductSKUInstructions] =
    useState(false)
  const [showSubPlanInstructions, setShowSubPlanInstructions] = useState(false)

  const squareIntegration = widgetInstall.integrations?.find(
    (integration) => integration.slug === 'square'
  )

  const { data, refetch } = useNhostFunction<{
    success: boolean
    subscriptionPlans?: SubscriptionPlan[]
  }>(
    'public-site-data',
    {
      widgetSlug,
      siteId,
      dataType: 'subscription_plans',
    },
    { skip: !squareIntegration }
  )

  const subscriptionPlans = data?.subscriptionPlans ?? []
  const productMapping =
    widgetInstall.data?.productMapping ?? emptyProductMapping
  const [editedProductMapping, setEditedProductMapping] =
    useState(productMapping)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    setEditedProductMapping(productMapping)
  }, [productMapping])

  const dirty = useMemo(() => {
    return !fastDeepEqual(productMapping, editedProductMapping)
  }, [productMapping, editedProductMapping])

  const saveMapping = async () => {
    if (!dirty || saving) return

    setSaving(true)

    const cleanedMapping = Object.entries(editedProductMapping).reduce(
      (map, [key, val]) => ({ ...map, [key]: val || undefined }),
      {}
    )

    const res = await nhost.graphql
      .request(UPDATE_PRODUCT_MAPPING, {
        id: widgetInstall.id,
        productMapping: cleanedMapping,
      })
      .catch((err) =>
        err instanceof Error ? err : new Error(JSON.stringify(err))
      )

    if (res instanceof Error) {
      setSaving(false)
      return toast.error({
        description: 'There was a problem saving, please try again.',
      })
    }

    await apollo.reFetchObservableQueries()

    setSaving(false)
  }

  return (
    <div
      className={`${className} mx-auto max-w-3xl w-full flex flex-col min-h-0 grow`}
      {...props}
    >
      {!squareIntegration ? (
        <div className="flex flex-col items-center">
          <p className="text-center mb-4">
            To get started, first connect this widget to your Square Account.
          </p>
          <SquareLinkingButton widgetInstallId={widgetInstall.id}>
            Connect your Square Account
          </SquareLinkingButton>
        </div>
      ) : (
        <div className="flex flex-col min-h-0 bg-white rounded p-4">
          <div className="flex justify-between mb-4 px-2">
            <h4 className="text-gray-700">
              Assign your <strong>Subscription Plans</strong> to your{' '}
              <strong>Product SKUs</strong>
            </h4>
            <Button
              variant="primary"
              disabled={!dirty || saving}
              onClick={saveMapping}
              PreIcon={
                saving
                  ? ArrowPathIcon
                  : dirty
                  ? CloudArrowUpIcon
                  : CheckCircleIcon
              }
              spin={saving}
            >
              {dirty ? 'Save Updates' : 'Up to date'}
            </Button>
          </div>
          <div className="flex justify-between px-2 text-gray-500 text-sm font-semibold">
            <span>
              Subscription Plans{' '}
              <InfoButton
                className="h-4 w-4"
                onClick={() => setShowSubPlanInstructions(true)}
              />
            </span>
            <span>
              Product SKUs{' '}
              <InfoButton
                className="h-4 w-4"
                onClick={() => setShowProductSKUInstructions(true)}
              />
            </span>
          </div>
          <ul className="px-2 divide-y divide-gray-300 overflow-y-auto shadow-inner rounded bg-gray-50">
            {subscriptionPlans.map((plan) => (
              <li className="py-2 flex items-center" key={plan.id}>
                <div className="flex flex-col mr-2 sm:mr-4">
                  <span className="text-gray-700 font-semibold mb-1">
                    {plan.subscriptionPlanData.name}
                  </span>
                  <span className="text-gray-400 text-sm"></span>
                </div>
                <TextInput
                  id={`sku-${plan.id}`}
                  placeholder="Product SKU"
                  className="ml-auto flex items-center flex-row shrink-0"
                  inputClass={clsx({
                    '!ring-2 !ring-green-500':
                      (editedProductMapping[plan.id] ?? '') !==
                      (productMapping[plan.id] ?? ''),
                  })}
                  collapseDescriptionArea
                  value={editedProductMapping[plan.id] ?? ''}
                  onChange={(e) =>
                    setEditedProductMapping((p) => ({
                      ...p,
                      [plan.id]: e.target.value,
                    }))
                  }
                />
              </li>
            ))}
            <div className="flex-center space-x-2 py-4">
              <Button
                variant="dismissive"
                PreIcon={ArrowPathRoundedSquareIcon}
                onClick={() => {
                  apollo.reFetchObservableQueries()
                  refetch()
                }}
              >
                Refresh Square Data
              </Button>
              <Button
                PreIcon={PlusIcon}
                onClick={() =>
                  window.open(
                    'https://squareup.com/dashboard/plans',
                    '_blank',
                    'noreferrer noopener'
                  )
                }
              >
                Add a Subscription Plan
              </Button>
            </div>
          </ul>
          <Modal
            show={showSubPlanInstructions}
            onClose={() => setShowSubPlanInstructions(false)}
          >
            <div>
              <p className="mb-4">
                Create or Update your subscription plans in your{' '}
                <a
                  className="text-primary-500 hover:text-primary-600 focus:text-primary-600 focus:outline-none"
                  href="https://squareup.com/dashboard/plans"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Square dashboard
                </a>
                .
              </p>
              <em className="italic text-gray-500 text-sm mb-4 block">
                Pro Tip: Make sure to check the <strong>Checkout link</strong>{' '}
                feature, or your customers won't be able to buy online!
              </em>
              <img
                className="w-full max-w-lg shadow-md mx-auto"
                src={checkTheCheckoutLink}
                alt="check the checkout link!"
              />
            </div>
          </Modal>
          <Modal
            show={showProductSKUInstructions}
            onClose={() => setShowProductSKUInstructions(false)}
          >
            <div>
              <p className="mb-2 text-primary-700">
                Find your Product SKUs in the Ecommerce tab -&gt; Manage Store
                -&gt; Catalog -&gt; Products. SKUs are visible next to each
                product name.
              </p>
              <em className="text-sm italic text-gray-500 mb-4">
                Pro tip: open a separate tab to visit the store so you don't
                lose your place and save time on page reloading.
              </em>
              <img
                className="w-full max-w-4xl shadow-md mx-auto"
                src={findTheSku}
                alt="Find the SKU Example"
              />
            </div>
          </Modal>
        </div>
      )}
    </div>
  )
}
