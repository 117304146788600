import { Button, ButtonProps } from '@8thday/react'
import { useNhostClient } from '@nhost/react'
import { useApolloClient } from '@apollo/client'
import React, { forwardRef, MouseEvent, useState } from 'react'
import { toast } from '@8thday/react'

export interface SquareLinkingButtonProps extends Omit<ButtonProps, 'ref'> {
  widgetInstallId?: string
}

export const SquareLinkingButton = forwardRef<
  HTMLButtonElement,
  SquareLinkingButtonProps
>(({ className = '', widgetInstallId, ...props }, ref) => {
  const [clicked, setClicked] = useState(false)
  const nhost = useNhostClient()
  const apollo = useApolloClient()

  const createIntegration = async (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (!widgetInstallId) return

    setClicked(true)

    const result = await nhost.functions.call<{ url: string }>(
      'integrations/link',
      {
        widgetInstallId,
        slug: 'square',
        payload: { currentUrl: window.location.href },
      }
    )

    if (result.res?.data?.url) {
      window.open(result.res.data.url, '_blank', 'noreferrer noopener')

      window.addEventListener('focus', focusListener)

      async function focusListener() {
        console.log('ayo')
        const stuff = await apollo.reFetchObservableQueries()
        console.log(stuff)
        setClicked(false)
        window.removeEventListener('focus', focusListener)
      }
    } else {
      setClicked(false)
      toast.error({
        message: 'Authorization Failed to Initiate',
        description: result.error?.message,
      })
    }
  }

  return (
    <Button
      disabled={!widgetInstallId || clicked}
      ref={ref}
      className={`${className}`}
      {...props}
      onClick={createIntegration}
    />
  )
})
