import { gql } from '@apollo/client'

export const UPDATE_PRODUCT_MAPPING = gql`
  mutation updateProductMapping($id: uuid!, $productMapping: jsonb!) {
    update_widget_install_by_pk(
      pk_columns: { id: $id }
      _append: { data: { productMapping: $productMapping } }
    ) {
      id
    }
  }
`
