import { XMarkIcon } from '@heroicons/react/24/outline'
import React, { ComponentProps } from 'react'
import { createPortal } from 'react-dom'

const modalRoot = document.createElement('div')
modalRoot.style.zIndex = '10000'

document.body.append(modalRoot)

export interface ModalProps extends ComponentProps<'div'> {
  show?: boolean
  onClose?(): void
}

export const Modal = ({
  className = '',
  show = false,
  onClose,
  children,
  ...props
}: ModalProps) => {
  if (!show) return null

  return createPortal(
    <div
      className="fixed backdrop-filter backdrop-blur-sm inset-0 flex-center bg-white bg-opacity-50"
      onClick={(e) => e.target === e.currentTarget && onClose?.()}
    >
      <div className="relative overflow-visible">
        <div
          className={`${className} p-4 rounded-md shadow-md bg-white min-w-1/3 min-h-1/4 max-h-content max-w-[90vw] overflow-y-auto`}
          {...props}
        >
          {children}
        </div>
        <button
          className="absolute -right-3 -top-3 rounded-full bg-white shadow p-1"
          onClick={onClose}
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
      </div>
    </div>,
    modalRoot
  )
}
