import { toast } from '@8thday/react'
import { useNhostClient, useUserId } from '@nhost/react'
import { useEffect, useState } from 'react'

export const useLogin = () => {
  const nhost = useNhostClient()
  const user = useUserId()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [outOfContext, setOutOfContext] = useState(false)

  useEffect(() => {
    const search = location.search

    if (user || loading || error) return

    const params = new URLSearchParams(search)

    if (params.get('oauth_error')) {
      const description = 'Authorization failed, please try again.'
      toast.error({ description })
      setError(new Error(params.get('oauth_error') ?? description))
      setOutOfContext(true)
      setLoading(false)
      return
    }

    if (params.get('success')) {
      toast.success({
        description: 'Authorization granted!',
      })

      setOutOfContext(true)
      setLoading(false)
      setError(null)

      return
    }

    if (
      !params.get('site_name') ||
      !params.get('timestamp') ||
      !params.get('secure_sig')
    ) {
      setLoading(false)
      setOutOfContext(true)

      return
    }

    setLoading(true)

    nhost.functions
      .call<{ refreshToken: string }>(
        `builder-apps/login${search}`,
        {},
        { headers: { 'Content-type': 'application/json' } }
      )
      .then(({ res, error }) => {
        setLoading(false)

        const data = res?.data
        if (error || !data) {
          setError(
            new Error(
              error
                ? `${error?.message}:${error.error}`
                : 'no refresh token returned'
            )
          )
          nhost.auth.signOut()
        } else {
          nhost.auth.refreshSession(data.refreshToken)
          if (params.get('sdk_url')) {
            loadScript(params.get('sdk_url'))
          }
        }
      })
      .catch((e) => {
        setLoading(false)
        setError(e)
      })
  }, [user, loading, nhost])

  return { error, loading, outOfContext }
}

function loadScript(script) {
  if (!document.querySelector(`script[src="${script}"]`)) {
    const el = document.createElement('script')
    el.src = script
    document.head.append(el)
  }
}
